import React , {useState} from 'react';
import { Button, Modal, Row ,Form , Col, Tooltip, message } from 'antd';
import { ScreenOne } from './screen-one';
import { ScreenTwo } from './screen-two';
import { ScreenThree } from './screen-three';
import Icon from '@ant-design/icons';
import { updateRouteTimings , getCitiesInfo, getCitiesFromRoutes} from './change-routes-timings.module';
import Clock from './../../images/clock.png';
import moment from "moment";
import { checkActionAllowed, isValid, isValidArrayWithValues} from "../../utils/utilities";
import { UPDATE_DAY_SPECIFIC_ROUTE_TIMING } from './../../utils/constant';
import { get24HoursTime, getFormatedDate, addMin } from '../../utils/date.utils';
import { getViacitiesDetails } from '../../actions/admin.action';
import { AdjustTimePopUp } from '../trip-create/adjust-time-pop-up/adjust-time-pop-up.component';

let intialScreen = "intial";
let sameTimeForAllRoutes = "same";
let differentTimeForEachRoute="different";
let editDifferentRoutes = "EditTimings";

export const ChangeRoutesTimingsComponent = (props) => {


    const {
        travelDate,
        scheduleId,
        tripId,
        onTimeUpdateSuccess
    } = props;


    const [screenName,setScreenaName] = useState(intialScreen);
    const [rowKeys,setRowKeys] = useState([]);
    const [selectedRoutes, setSelectedRoutes] = useState([]);
    const [disableAdjustBtn, setDisableAdjustBtn] = useState(false);

    let [cities,setCities] = useState([]);

    const onSuccess=()=>{
        onTimeUpdateSuccess();
        handleCancel();
    }

    const [screenOneForm] = Form.useForm();
    const [screenTwoForm] = Form.useForm();

    const decreaseScreenNumber=()=>{
        if(screenName===intialScreen){
            handleCancel();
        }else if(screenName===sameTimeForAllRoutes||screenName===differentTimeForEachRoute){
            setScreenaName(intialScreen);
        }else if(screenName===editDifferentRoutes){
            setScreenaName(differentTimeForEachRoute);
        }
    }
  
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        getViacitiesDetails(scheduleId,moment(travelDate),(data)=>{
            setSelectedRoutes(data);
            setCities(getCitiesFromRoutes(data));
        },()=>{},0);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setScreenaName(intialScreen);
        screenOneForm.resetFields();
        screenTwoForm.resetFields();
    };


    const onSubmit=(e)=>{
        e.preventDefault();
        if(screenName==intialScreen){
            screenOneForm.validateFields()
            .then(values => {
                let {
                    isAllCities
                }= values;
                if(isAllCities){
                    setScreenaName(sameTimeForAllRoutes);
                }else{
                    setScreenaName(differentTimeForEachRoute);
                }
            })
            .catch(errorInfo => {
                console.log({errorInfo});
            })
        }else if(screenName==differentTimeForEachRoute){
            setScreenaName(editDifferentRoutes);
        }else{
            let citiesInfo = cities;
            if(screenName===sameTimeForAllRoutes){
                screenTwoForm.validateFields()
                .then(values=>{
                    let {
                        newStartTiming
                    } = values;
                    let justTime = get24HoursTime(newStartTiming);
                     citiesInfo = getCitiesInfo(selectedRoutes,newStartTiming);
                     updateRouteTimings(citiesInfo,tripId,scheduleId,true,justTime,onSuccess,(errMsg)=>{
                        message.error(errMsg);
                     });
                })
            }else{
                updateRouteTimings(citiesInfo,tripId,scheduleId,false,null,onSuccess,(errMsg)=>{
                    message.error(errMsg);
                 });
            }
        }
    }

    const setCitiesForAdjustTime = (fromStop, toStop, mins) => {

        let updateTime = false;
        let newCities = cities;
        let selectedCities = []
        newCities.forEach((element,index1) => {
            if(fromStop == element.city){
                updateTime = true;
            }
            if(updateTime){
                let day = element.dayNo;
                selectedCities.push(element.city);
                if(isValid(element.oldTiming)){
                    let momentObject = moment(element.oldTiming, 'HH:mm');
                    let time = addMin(momentObject,mins).format("HH:mm:ss");
                    if(time<element.oldTiming&&mins>0){
                        day = day + 1;
                    }
                    if(time>element.oldTiming&&mins<0){
                        day = day - 1;
                    }
                    newCities[index1].newTiming = time;
                    newCities[index1].dayNo = day;
                }
            }
            if(toStop == element.city){
                updateTime= false;
            }
        });
        setRowKeys(selectedCities);
        setCities(newCities);
        if (selectedCities.length>0) {
            setDisableAdjustBtn(true);
        }
        else {
            setDisableAdjustBtn(false);
        }
        return [...newCities];
    }

    if (checkActionAllowed(UPDATE_DAY_SPECIFIC_ROUTE_TIMING)){
        let className = isModalVisible?"displayNone":'';
        return (
            <div>
                      <Tooltip placement="topLeft" title={"Update route timings"} overlayClassName={className}>
                          <Button onClick={showModal} ghost className="open-select-seat-btn">
                              <Icon component={() => (<img src={Clock} style={{width:'23px',padding:'1px'}} />)} />
                          </Button>
                      </Tooltip>
                <Modal title={"Change Timing for trip start date: "+getFormatedDate(travelDate)} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                   {screenName===intialScreen&&
                      <ScreenOne
                      screenOneForm={screenOneForm}/>
                   }
                   {screenName===sameTimeForAllRoutes&&
                      <ScreenTwo
                      screenTwoForm={screenTwoForm} />
                   }
                   {screenName===differentTimeForEachRoute&&
                      <ScreenThree
                      isEditTimings={false}
                      isSelectRoutes={true}
                      rowKeys={rowKeys}
                      setRowKeys={setRowKeys}
                      cities={cities}
                      setCities={setCities}
                      tripId={tripId}
                      setDisableAdjustBtn = {setDisableAdjustBtn}
                      />
                   }
                   {screenName==editDifferentRoutes&&
                          <ScreenThree
                          isEditTimings={true}
                          isSelectRoutes={false}
                          rowKeys={rowKeys}
                          setRowKeys={setRowKeys}
                          cities={cities}
                          setCities={setCities}
                          tripId={tripId}
                          setDisableAdjustBtn = {setDisableAdjustBtn}
                          />
                   }
                   <Row gutter={[16,16]}>
                        {screenName===differentTimeForEachRoute&&<Col>
                            <AdjustTimePopUp
                                tripCitiesWithStops={cities}
                                adjustTimeForStop = {false}
                                disableAdjustBtn = {disableAdjustBtn}
                                onUpdate={(fromStop,toStop,mins)=>{
                                    setCitiesForAdjustTime(fromStop,toStop,mins);
                                }}
                                startTime={isValidArrayWithValues(selectedRoutes)?selectedRoutes[0].start_time:'00:00'}
                            />
                        </Col>}
                       <Col>
                          <Button onClick={decreaseScreenNumber}>{screenName==intialScreen?"Exit":"Back"}</Button>
                       </Col>
                       <Col>
                          <Button onClick={onSubmit} type="primary" disabled = {screenName==differentTimeForEachRoute&&!disableAdjustBtn?true:false}>{screenName==editDifferentRoutes||screenName==sameTimeForAllRoutes?"Update":"Continue"}</Button>
                       </Col>
                   </Row>
                </Modal>
            </div>
        );
    }
    else{
        return null;
    }
};
