import React , { useEffect, useState } from "react";
import { Button, Tooltip, Modal, Table , Row , Col, InputNumber, message} from "antd";
import './seat-price.style.scss';
import moment from 'moment';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import {
    updateScheduleTripFare
} from './../edit-fare-component/edit-fare.module';
import { checkActionAllowed, isValid, isValidArray } from "../../utils/utilities";
import { ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL } from "../../utils/constant";
import {
    ArrowDownOutlined
   } from '@ant-design/icons';
import { OriginDestionationFilter } from "../common-components/origin-destination-filter/origin-destination-filter.component";
import { getOriginsDestinations } from './seat-price.module';
import { getViacitiesDetails } from "../../actions/admin.action";

const getSeatNumbers=(seats)=>{
    let text=null , array = [] , placeholder = null;
    seats.forEach((row)=>{
        if(isValid(text)){
            text=text +","+ row.seatNumber;
        }else{
            text= row.seatNumber;
        }
        if(!isValid(placeholder)){
            placeholder=row.fare;
        }else{
            if(placeholder !== row.fare){
                placeholder = "Multiple"
            }
        }
        array.push(row.seatNumber);
    })
    return {text,array,placeholder};
}

export const SeatPriceComponent = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [seatNumbersString , setSeatNumbers] = useState(null);
    const [seatNumbersArray , setSeatNumbersArray] = useState(null);
    const [placeHolderTxt , setPlaceHolderTxt] = useState(null);

    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [originsFiltered, setOriginsFiltered] = useState(['all']);
    const [destinationsFiltered, setDestinationsFiltered] = useState(['all']);

    const {
        travelDate,
        scheduleId,
        description,
        selectedSeats,
        bus,
        onSuccess,
        disableSetPrice
    } = props;

    useEffect(()=>{
        let{
            text,
            array,
            placeholder
        } = getSeatNumbers(selectedSeats);
        setSeatNumbers(text);
        setSeatNumbersArray(array);
        setPlaceHolderTxt(placeholder);
    },[selectedSeats]);

    const dispatch = useDispatch();
    const setloading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const showModal = () => {
      setIsModalVisible(true);
      setloading(true);
      getViacitiesDetails(scheduleId,moment(travelDate),(data)=>{
        let {filteredOrigins,filteredDestinations} = getOriginsDestinations(data);
        setOrigins(filteredOrigins);
        setDestinations(filteredDestinations);
        setRoutes(data);
        setFilteredRoutes(data);
        setloading(false);
        },
        ()=>{
            setloading(false);
        },0);
    };
  
    const onOriginChange =(values)=>{
        let formatedData = [];
        setOriginsFiltered(values);
        routes.forEach(element => {
            if((values.indexOf(element.origin) !== -1 || values.length === 0) && (destinationsFiltered.length === 0 || destinationsFiltered.indexOf(element.destination) !== -1)){
                formatedData.push(element);
            }
        });
        setFilteredRoutes(formatedData);
    }

    const onDestionationChange =(values)=>{
        let formatedData = [];
        setDestinationsFiltered(values);
        routes.forEach(element => {
            if((values.indexOf(element.destination) == -1 || values.length === 0) && (originsFiltered.length === 0 || originsFiltered.indexOf(element.origin) !== -1)){
                formatedData.push(element);
            }
        });
        setFilteredRoutes(formatedData);
    }


    const routeColumns =[
        {
            dataIndex:'origin',
            key:'origin',
            render:(text,row)=>{
                return (
                    <div>{`${row.origin} - ${row.destination}`}</div>
                );
            }
        },
        {
            dataIndex:'fare',
            key:'fare',
            render:(text,row,rowIndex)=>{
                return(
                    <div>
                        <InputNumber 
                        placeholder={placeHolderTxt} 
                        value={row.value}
                        onChange={(value)=>{
                            let fareJsonObject=JSON.parse(row.fare_json);
                            if(!isValidArray(fareJsonObject)){
                                fareJsonObject = {}
                            }
                            seatNumbersArray.forEach(element => {
                                fareJsonObject[`SS_${element}`]=value;  
                            });
                            filteredRoutes[rowIndex].fare_json=JSON.stringify(fareJsonObject);
                            filteredRoutes[rowIndex].value=value;
                            filteredRoutes[rowIndex].is_fare_edited=true;
                            setFilteredRoutes([...filteredRoutes]);
                        }}/>
                        {rowIndex==0&&filteredRoutes.length>1&&
                            <Tooltip placement="topLeft" title={"Copy fare of 1st route to all routes"}>
                                <ArrowDownOutlined 
                                    style={{paddingLeft:10}}
                                    size={'large'}
                                    onClick={()=>{
                                        let firstRow = filteredRoutes[0].fare_json;
                                        let firstValue = filteredRoutes[0].value;
                                        for (let index = 0; index < filteredRoutes.length; index++) {
                                            filteredRoutes[index].fare_json = firstRow;
                                            filteredRoutes[index].value = firstValue;
                                            filteredRoutes[index]["is_fare_edited"]=true;
                                        }
                                        setFilteredRoutes([...filteredRoutes]);
                                    }}
                                    type="arrow-down" 
                                />
                            </Tooltip>
                        }
                    </div>
                 
                )
            }
        }
    ];

    
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    if(checkActionAllowed(ACTION_TO_EDIT_FARE_FROM_AGENT_PORTAL)){
        return (
            <span>
                <Button onClick={showModal} disabled={disableSetPrice} className="seat-price-btn">Seat Price</Button>
                 <Modal title="Set Seat Price" 
                 maskClosable={false}
                 visible={isModalVisible} 
                 onOk={handleOk} 
                 onCancel={handleCancel}
                 footer={null}
                 >
                     <div className="seat-price-root">
                        <div className="fontWeightBold">{description}</div>
                        <div className="">Travel date : {travelDate}</div>
                        <div className="fontWeightBold">{seatNumbersString}</div>
                        <OriginDestionationFilter 
                            origins={origins}
                            destinations={destinations}
                            onOriginChange={onOriginChange}
                            onDestionationChange={onDestionationChange}
                            origin={originsFiltered}
                            destination={destinationsFiltered}
                        />
                        <Table 
                            dataSource={filteredRoutes}
                            columns={routeColumns}
                            showHeader={false}
                            pagination={false}
                            scroll={{ y: 300,x: "max-content" }}
                            className="marginTop edit-fare-table"
                            tableLayout = "auto"
                        />
                        <Row gutter={[16,16]} justify="center" className="marginTop"> 
                            <Col>
                                <Button className=""
                                onClick={handleCancel}
                                >Cancel</Button>
                            </Col>
                            <Col>
                                <Button className="" 
                                onClick={()=>{
                                    setloading(true);
                                    updateScheduleTripFare(
                                        filteredRoutes,
                                        [travelDate],
                                        scheduleId,
                                        bus,
                                        false,
                                        seatNumbersArray,
                                        (msg)=>{
                                            onSuccess(msg);
                                            setloading(false);
                                        },
                                        (emsg)=>{
                                            setloading(false);
                                            message.error(emsg);
                                        })
                                }}
                                >Update</Button>
                            </Col>
                        </Row>
                     </div>
                </Modal>
            </span>
        )
    }else{
        return null;
    }
}