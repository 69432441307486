import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { config } from './config'

const specialCharsRegex = /-|_|\\|\/|,/g

const insertSoftHyphens = string =>
  string.replace(specialCharsRegex, match => `\u00ad${match}`)

const styles = StyleSheet.create({
  table: {
    width: '100%',
    borderColor:'#000000',
    borderTopWidth:1,
    borderLeftWidth:1
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor:'#000000',
    borderBottomWidth:1
  },
  headerCell: {
    fontWeight: 'heavy',
    width: '11.11%',
    fontSize: config.normalFontSize,
    textAlign:'center',
    borderColor:'#000000',
    borderRightWidth:1,
    height:"100%",
  },
  headerCellWidthBold:{
    fontWeight: 'heavy',
    width: '11.11%',
    fontSize: config.normalFontSize + 1,
    textAlign:'center',
    borderColor:'#000000',
    borderRightWidth:1,
    height:"100%",
    fontWeight: 'bold',
    fontFamily: 'Lato'
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cell: {
    width: '11.11%',
    fontSize: config.normalFontSize,
    textAlign:'center',
    borderColor:'#000000',
    borderRightWidth:1,
    height:"100%"
  },
  cellWithBold:{
    width: '11.11%',
    textAlign:'center',
    borderColor:'#000000',
    borderRightWidth:1,
    height:"100%",
    fontWeight: 'bold',
    fontFamily: 'Lato',
    fontSize: config.normalFontSize + 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor:'#000000',
    borderBottomWidth:1
  }
})

const Table = ({ children }) => <View style={styles.table}>{children}</View>

const TableHeader = ({ children }) => (
  <View style={styles.header}>{children}</View>
)

const TableHeaderCell = ({ children , style }) => (
  <Text style={[global.tripSheetBold == "tripSheetBold"?styles.headerCellWidthBold:styles.headerCell,style]}>{children}</Text>
)

const TableBody = ({ children }) => <View style={styles.body}>{children}</View>

const TableRow = ({ children }) => <View style={styles.row}>{children}</View>

const TableCell = ({ children , style}) => (
  <Text style={[global.tripSheetBold == "tripSheetBold"?styles.cellWithBold:styles.cell,style]}>
    {/* {typeof children === 'string' ? insertSoftHyphens(children) : children} */}
    {children}
  </Text>
)

Table.Header = TableHeader
Table.Body = TableBody
Table.Row = TableRow
Table.HeaderCell = TableHeaderCell
Table.Cell = TableCell

export default Table
