import React from "react";
import { Button, message } from "antd";
import { handleClearSeats } from "./clear-seat.module";
import { showConfirm } from "../custom-components/custom-component";


export const ClearSeatBtn = (props) => {

    const{
        seatStatus,
        travellerStatus,
        tripId,
        ticketId,
        seatNumber,
        cutRouteEnabled,
        reload=()=>{}
    }=props;

    const onFailure=()=>{
        message.error("Failed to to update seat status!")
    }

    const onSuccess =()=>{
        reload();
    }

    const onClick=()=>{
        showConfirm(()=>{
            handleClearSeats(tripId,seatNumber,ticketId,cutRouteEnabled,onSuccess,onFailure);
        },"Clear Seat Status","Are you sure to clear seat status?");
    }

    if(seatStatus == "Confirmed" && travellerStatus != "Confirmed"){
        return (
            <div><Button onClick={onClick}>Clear</Button></div>
        )
    }else{
        return null
    }
}