import { isValidArray , getValuesOfJSonObject, isValid, sortJsonObject   } from "../../utils/utilities";
import {UPDATE_TRIP_ROUTE_TIMINGS_URL, CACHE_REFRESH_TRIP_ROUTE_TIMING_URL } from './../../utils/api-url';
import { getMinsBetweenTwoTimes , get24HoursTime , addMin , getMySqlAddMinToTime} from './../../utils/date.utils';
import moment from 'moment';
import { message } from "antd";


export const getCitiesFromRoutes = (routes) =>{
    let formatedData={};
    if(isValidArray(routes)&&routes.length>0){
        routes.forEach(element => {
            if(!isValidArray(formatedData[element.origin])){
                formatedData[element.origin]={
                    city:element.origin,
                    oldTiming:element.start_time,
                    newTiming:element.start_time,
                    dayNo:element.origin_day
                };
            }

            if(!isValidArray(formatedData[element.destination])){
                formatedData[element.destination]={
                    city:element.destination,
                    oldTiming:element.end_time,
                    newTiming:element.end_time,
                    dayNo:element.destination_day
                };
            }
        });
    }
    let cities = getValuesOfJSonObject(formatedData);
    let sortByTime= sortJsonObject(cities,'oldTiming',false); 
    let finalSort= sortJsonObject(sortByTime,'dayNo',true);
    return finalSort;
}

const getOffSetInMin=(time,day)=>{
    let mins=0 , perdaymin=1440;
    if(day>0){
      mins = day*perdaymin;
    }
    let diffMins=getMinsBetweenTwoTimes('00:00:00',time);
    mins = mins + diffMins;
    return mins;
}

  
const getDay=(duration,offset)=>{
    let newOffset = duration + offset;
    return ~~(newOffset/1440);
}

export const getCitiesInfo = (routes,newStartTiming) =>{
    let formatedData={};
    if(isValidArray(routes)&&routes.length>0){
        let oldStartTiming = getOldStartTime(routes);
        let offset = getMinsBetweenTwoTimes(oldStartTiming,newStartTiming);
        routes.forEach(element => {
           if(element){
            if(!isValidArray(formatedData[element.origin])){
                let duration = getOffSetInMin(element.start_time,element.origin_day);
                let originDay = getDay(duration,offset);
                formatedData[element.origin]={
                    city:element.origin,
                    oldTiming:element.start_time,
                    newTiming:get24HoursTime(addMin(moment(element.start_time,'HH:mm'),offset)),
                    dayNo:originDay,
                };
            }
            if(!isValidArray(formatedData[element.destination])){
                let duration = getOffSetInMin(element.end_time,element.destination_day);
                let destinationDay = getDay(duration,offset);
                formatedData[element.destination]={
                    city:element.destination,
                    oldTiming:element.end_time,
                    newTiming:get24HoursTime(addMin(moment(element.end_time,'HH:mm'),offset)),
                    dayNo:destinationDay,
                };
            }
           } 
        });
    }
    return getValuesOfJSonObject(formatedData);
}

const getOldStartTime =(routes)=>{
    let oldStartTime=null;
    routes.forEach((element)=>{
        if(!isValidArray(oldStartTime)||
        ((oldStartTime.origin_day===element.origin_day&&oldStartTime.start_time>element.start_time)||(oldStartTime.origin_day>element.origin_day)))
        {
            oldStartTime=element;
        }
    })
    return oldStartTime.start_time;
}

export const updateRouteTimings = (cities,tripId,scheduleId,changeForallRoutes,newStartTime,onSuccess,onFailure=()=>{
    return false
}) =>{
    return fetch(UPDATE_TRIP_ROUTE_TIMINGS_URL,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cities:cities,
        operatorCode:global.operatorCode,
        tripId: tripId,
        scheduleId:scheduleId,
        changeForallRoutes:changeForallRoutes,
        updatedBy : global.userName,
        newStartTime:newStartTime
      }) // body data type must match "Content-Type" header
     }
    ).
    then((Responce)=>Responce.json()).
    then((findresponce)=>
    {
        if(findresponce.Status=="SUCCESS"){
            return onSuccess();
        }else{ 
            return onFailure(findresponce.Message);
        }
    })
}

export const cacheRefreshTripRouteTime = () => {
    fetch(CACHE_REFRESH_TRIP_ROUTE_TIMING_URL,
    {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        }) // body data type must match "Content-Type" header
       }
      ).
      then((Response)=>Response.json()).
      then((findresponse)=>
      {
          if(findresponse.Status=="SUCCESS"){
              message.success(findresponse.Message);
          }else{
              message.error(findresponse.Message);
          }
      })
}