import React, { useEffect, useState ,useContext } from 'react';
import { Form, Input, Row , Col , Radio , Select , Checkbox , Typography, DatePicker , TimePicker, message } from 'antd';
import { blockedSeatTicketIdSelector, passengerInfoSelector } from './../../selector/passenger-form.selector';
import { useDispatch, useSelector } from 'react-redux';
import { isValidArray,getValuesOfJSonObject,isValid, isValidString, isValidArrayWithValues } from '../../utils/utilities';
import { getTimeStampInReadableFormat , convertDateStringToDate , getLocalDate , incrementDays , getUIDateFormat} from './../../utils/date.utils';
import {getDetailsToBookTicket,getTicketFare , PassengerSeatsTable , getAnyBlockedSeatsExit } from './passenger-form.module';
import './passenger-form.style.scss';
import { bookTicket,blockTicket , onPassengerFormChange} from './../../actions/home.action';
import { CustomModal } from './../custom-components/custom-component';
import { ACTION_BOOK_TICKET_ALLOWED , ACTION_BLOCK_TICKET_ALLOWED ,ACTION_PHONE_BLOCK_ALLOWED, ACTION_TO_UPDATE_AGENT_IN_TICKET, ACTION_TO_VIEW_SET_GENDER_BTN} from './../../utils/constant';
import { checkActionAllowed } from './../../utils/utilities';
import { ReleaseSeatsComponent } from './../release-seats/release-seats.component';
import { resetPassengerForm } from './../../actions/ticket.action';
import { BlockByAdminComponent } from './../block-by-admin/block-by-admin.component';
import { DeviceContext } from './../../components/device-provider/device-provider';
import { addMin ,setTimeToDateObject , getAPIDateFormat , getTimeFormat , getLocalDateAndTime} from './../../utils/date.utils';
import moment from 'moment';
import { SeatPriceComponent } from './../seat-price-component/seat-price.component';
import {
    AddDiscount,
    EditDiscount
} from './../discount-component/discount.component';
import { getAgentsByOperatorCode } from '../../actions/agents.actions';
import { ChangeGenderReservation } from '../change-gender-reservation/change-gender-reservation';
import { CopyPaymentLink } from '../copy-payment-link/copy-payment-link.component';

const {
    Text
} = Typography;

let blockButtonType="Block";
let bookButtonType="Book";
let phoneBlock="Phone block";
let sendPaymentLink ="Send payment link";


const { Option } = Select;

const DrawLine =({className})=>{

    return(
        <div className={className}>

        </div>
    )
}


const getDropPointsDate=(selectedSchedule,selectedDate)=>{
    let destinationDayNo=0;
    if(selectedSchedule.destination_day>selectedSchedule.origin_day){
        destinationDayNo = selectedSchedule.destination_day - selectedSchedule.origin_day;
    }
    let incrementDate = incrementDays(selectedDate,destinationDayNo);
    return incrementDate;   
}

const optionValueText = (location,time,incrementDate=null) =>{
    return (
        <Row style= {{whiteSpace: 'break-spaces'}}>
            <Col span={14} >
                {location}
            </Col>
            <Col span={10} >
                {isValid(incrementDate)?time+" ("+incrementDate + ")":time}
            </Col>
        </Row>
    )
}

const getPointValue=(value,landmark)=>{
    return (<div>
                {value}
                {isValid(landmark)?
                <span style={{fontSize:11}}>
                    {landmark}
                </span>
                :null
                }
            </div>);
}

const renderBoardingsPoints=(boardingPoints,selectedDate)=>
{
    let boardingPointsArray=[] , nextDayBoardingPoints=[];
    if(isValidArray(boardingPoints)&&boardingPoints.length>0){
        boardingPoints.forEach((element,index) => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            let timing= getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.origin_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(selectedDate,element.origin_day));
                    let value = optionValueText(element.location,timing,incrementDate)
                    desc = desc +" ("+incrementDate + ")";
                    nextDayBoardingPoints.push(<Option searchValue = {desc} value={element.id} className={[(index+1)%2==0?'point-even':'point-odd', element.pointPassed&&"passed-point"]}>
                            {getPointValue(value,element.landmark)}
                    </Option>);
                }else{
                    let value = optionValueText(element.location,timing)
                    boardingPointsArray.push(<Option searchValue = {desc} value={element.id} className={[(index+1)%2==0?'point-even':'point-odd', element.pointPassed&&"passed-point"]}>
                            {getPointValue(value,element.landmark)}
                    </Option>);
                }   
            }
        });
    }
    return boardingPointsArray.concat(nextDayBoardingPoints);
}



const renderDropPoints=(dropPoints,selectedDate,selectedSchedule)=>
{
    let dropDate = selectedDate;
    dropDate=getDropPointsDate(selectedSchedule,selectedDate);
    let dropPointsArray=[];
    if(isValidArray(dropPoints)&&dropPoints.length>0){
        dropPoints.forEach((element,index) => {
            let desc= element.location +" " +getTimeStampInReadableFormat(element.timing);
            let timing= getTimeStampInReadableFormat(element.timing);
            if(isValid(element)){
                if(element.destination_day>0||selectedSchedule.destination_day>0){
                    let incrementDate = getUIDateFormat(incrementDays(dropDate,element.destination_day));
                    let value = optionValueText(element.location,timing,incrementDate)
                    desc = desc +" ("+incrementDate + ")";
                    dropPointsArray.push(<Option searchValue = {desc} value={element.id} className={(index+1)%2==0?'point-even':'point-odd'}>
                        {getPointValue(value,element.landmark)}
                    </Option>);
                }else{
                    let value = optionValueText(element.location,timing)
                    dropPointsArray.push(<Option searchValue = {desc} value={element.id} className={(index+1)%2==0?'point-even':'point-odd'}>
                        {getPointValue(value,element.landmark)}
                    </Option>)
                }
            }        
        });
    }
    return dropPointsArray;
}

export const PassengerForm = (props) => {

    const{
        selectedSchedule,
        fetchSeats ,
        stopPoint,
        selectedDate,
        onFareUpdateSuccess,
        setSelectedTripRoute
    } = props;

  const [form] = Form.useForm();
  let defalutTime = addMin(null,12).format('HH:mm:ss');
  let defalutDate = moment().utcOffset("+05:30");
  const [releaseDate , setReleaseDate] = useState(defalutDate);
  const [releaseTime , setReleaseTime] = useState(defalutTime);
  const [disableSetPrice , setDisableSetPrice] = useState(false);

  const dispatch=useDispatch();

  const {
    isDesktop
    }=useContext(DeviceContext);

  const {
      selectedSeats,
      emailId,
      phoneNumber,
      boardingId,
      dropId,
      timeOfRelease
    }=useSelector(passengerInfoSelector);

    const ticketId = useSelector(blockedSeatTicketIdSelector)

    let isPhoneBlockSeat= isValidString(timeOfRelease)?true:false;
    let isSendPhonePaymentLink = global.send_payment_link?true:false;
    
  const [seats,setSeats]=useState([]);
  const [formValues , setFormValues]=useState({});
  const [copyPassenger,setCopyPassengerInfo]=useState(false);
  const [boardingDropManditory,setBoardingDropManditory]=useState(true);
  const [discountValue,setDiscountValue]=useState(0);
  const [agentsList, setAgentsList] = useState(null);

  const updateAgentList= (agentData) => {
      let tempAgents = [];
      agentData.map((data) => {
          let privilege = data.privilege.toLowerCase();
          if (privilege.includes('agent') || data.id == global.agentId) {
              tempAgents.push(<Option value = {data.id}>{data.name}</Option>);
          }
      })
      setAgentsList(tempAgents);
  }

  let isAnyBlockedSeatExits=getAnyBlockedSeatsExit(seats);

  useEffect(() => {
      if (checkActionAllowed(ACTION_TO_UPDATE_AGENT_IN_TICKET)){
        getAgentsByOperatorCode(updateAgentList,(err)=> {console.log("Error in fetching agents by operator code", err)});
      }
      else {
          setAgentsList(null);
      }
  },[])

  useEffect(()=>{
    if(isValid(timeOfRelease)){
        setReleaseDate(moment(getAPIDateFormat(timeOfRelease)));
        setReleaseTime(getTimeFormat(timeOfRelease));
    }else{
        setReleaseDate(defalutDate);
        setReleaseTime(defalutTime);
    }
  },[timeOfRelease]);

  useEffect(()=>{
      let seatsArray=getValuesOfJSonObject(selectedSeats);
      setSeats(seatsArray);
    form.setFieldsValue({
        passengerDetails:seatsArray
    });
    if(seatsArray.length === 0){
        setDisableSetPrice(false);
    }
  },[selectedSeats])

  useEffect(()=>{
    form.setFieldsValue({
        emailId:emailId,
        phoneNumber:phoneNumber,
        boardingPointId:boardingId,
        dropPointId:dropId

    })
  },[emailId,phoneNumber,boardingId,dropId])

  if(!isValidArray(seats)||seats.length<=0){
      return null
  }

  const handleSubmit =  (e , buttonType) => {
    e.preventDefault();
    if(buttonType===bookButtonType||buttonType===sendPaymentLink){
        setBoardingDropManditory(true);
    }else{
        setBoardingDropManditory(false);
    }
    return form.validateFields()
    .then(values => {
        setFormValues(values);
      return true;
    }).catch((err)=>{
      return false;
    })
}

const onSuccess=()=>{
    fetchSeats(selectedSchedule.trip_route_id);
    resetForm();
    setSelectedTripRoute(selectedSchedule.trip_route_id)
    dispatch(resetPassengerForm());
    setDiscountValue(0);
}

const onBookFailure = (loadSeats) => {
    if (loadSeats) {
        fetchSeats(selectedSchedule.trip_route_id);
    }
}

const bookAction=()=>{
      dispatch(bookTicket(getDetailsToBookTicket(formValues,selectedSchedule,null,false,discountValue,ticketId),onSuccess, onBookFailure));
}

const blockAction =(buttonType)=>{
    let datetime = null ;
    let isSendPaymentLink = false;
    if((buttonType === phoneBlock || buttonType === sendPaymentLink) && isValid(releaseDate)){
        datetime =  setTimeToDateObject(releaseDate,releaseTime) ; 
        if(buttonType === sendPaymentLink){
            isSendPaymentLink = true;
        }
    }
    dispatch(blockTicket(getDetailsToBookTicket(formValues,selectedSchedule,datetime , isSendPaymentLink,discountValue,ticketId),onSuccess));
}

const resetForm=()=>{
    form.setFieldsValue({
        passengerDetails:[],
        phoneNumber:null,
        emailId:null,
        boardingPointId:null,
        dropPointId:null
    })
}

const onFormChange=({
    fullName,
    age,
    gender,
    seatId
})=>{
    dispatch(onPassengerFormChange({
        fullName,
        age,
        gender,
        seatId
    }))
}


const ConfirmBody=({isShowMins})=>{
    return (
        <div>
            <Row>
                <h3>
                    Confirm Booking
                </h3>
            </Row>
            <Row>
                <DrawLine className={"confirm-booking-underline marginBottom"}/>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>Travel Date : </Text>
                        {getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split("T")[0]))}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>Trip Description : </Text>
                        {selectedSchedule.description}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>
                        <Text strong>No of seats : </Text>
                        {seats.length}
                    </Text>
                </Col>
            </Row>
            <div>
                <PassengerSeatsTable 
                    formValues={formValues}
                    boardingPoints={stopPoint.pickupDetails}
                    discountValue={discountValue}
                    bus={selectedSchedule.bus}
                />
            </div>
            {isShowMins &&
                <div className='margin'>
                    <DatePicker 
                     className='marginLeft marginTop'
                     placeholder="Select date"
                     defaultValue={defalutDate}
                     value={releaseDate}
                     format="DD-MM-YYYY"
                     onChange={(date)=>{
                         if(isValid(date)){
                            setReleaseDate(date);
                         }else{
                            setReleaseDate(moment().utcOffset("+05:30"));
                         }
                     }}
                    />
                    <TimePicker 
                    className='marginLeft marginTop remove-clear-icon'  
                    placeholder='"Select time'
                    defaultValue={moment(defalutTime, 'HH:mm:ss')}
                    value={moment(releaseTime, 'HH:mm:ss')}
                    use12Hours 
                    format="h:mm A" 
                    onChange={(time,timeString)=>{
                        if(isValid(time)){
                            setReleaseTime(time.format('HH:mm:ss'));
                        }else{
                            setReleaseTime('00:00:00');
                        }
                    }} 
                    style={{ width: 140 }}
                    />
                </div>
            }
        </div>
    )
}

let {
    baseFare,
    cgst,sgst,
    discountedCostWithoutGst,
    totalCost
} = getTicketFare(seats,discountValue,selectedSchedule.bus);
   
let isDiscountExits=baseFare>discountedCostWithoutGst?true:false;

if(!isValidArray(selectedSchedule)||!isValidArray(selectedSchedule.trip)||!isValid(selectedSchedule.trip.id)){
    return null
}
  return (
    <div>
        <h3>Passenger Details</h3>
        <DrawLine className={"passenger-details-underline"}/>
        <div className='copy-same-name-txt'>
            <Checkbox 
            value={copyPassenger}
            onChange={(e)=>{
                setCopyPassengerInfo(e.target.checked);
                if(e.target.checked){
                    let passengerDetails=form.getFieldValue('passengerDetails');
                    for (let index = 1; index < passengerDetails.length; index++) {
                        passengerDetails[index].fullName=passengerDetails[0].fullName;
                        passengerDetails[index].age=passengerDetails[0].age;
                    }
                    form.setFieldsValue({
                        passengerDetails:[...passengerDetails]
                    })
                }
            }}
            >Copy same name and age</Checkbox>
        </div>
        {/* <Row gutter={[16,0]}>
            <Col>
                <Text className="seatNumberInput">
                    Seat #
                </Text>
            </Col>
            {isDesktop&&
                <Col className='passengerNameLabel'>
                    <Text>
                        * Passenger Name
                    </Text>
                    <QuestionCircleOutlined />
                </Col>
            }
            {isDesktop&&
                <Col className='genderLabel'>
                    <Text>
                       * Gender
                    </Text>
                </Col>
            }
            {isDesktop&&
            <Col className='ageLabel'>
                <Text>
                    * Age
                </Text>
            </Col>
            }
            {isDesktop&&
            <Col className='fareLabel'>
                <Text>
                    Fare 
                </Text>
            </Col>
            }
        </Row> */}
        <Form
        form={form}
        layout="vertical"
        initialValues={{ passengerDetails: []}}
        onValuesChange={(changedValues,allValues)=>{
            let allPassengerDetails=allValues.passengerDetails;
            let changedPassengerDetails=changedValues.passengerDetails;
            if(allPassengerDetails.length>1&&copyPassenger&&isValidArray(changedPassengerDetails)){
                for (let index = 0; index < changedPassengerDetails.length; index++) {
                    if(isValidArray(changedPassengerDetails[index])){
                        let {
                            fullName,
                            age
                        }=changedPassengerDetails[index];
                        for (let j = 0; j < allPassengerDetails.length; j++) {
                            if(isValid(fullName)){
                                allPassengerDetails[j].fullName=fullName;
                            }else if(isValid(age)){
                                allPassengerDetails[j].age=age;
                            }    
                        }
                        break;
                    }
                }
                form.setFieldsValue({
                    passengerDetails:[...allPassengerDetails]
                });
            }
            setDisableSetPrice(true);
        }}
        >
            <Form.List name="passengerDetails">
            {(fields, { add, remove }) => (
                <>
            {fields.map(({ key, name, id,fieldKey, ...restField }) => {
                let isShowLabel = key==0&&isDesktop?true:false;
                return(
                    <div className="passenger-block">
                        <Row gutter={[16,16]}>
                            <Col>
                                <Form.Item
                                    label={isShowLabel?"Seat #":null}
                                    {...restField}
                                    name={[name, 'seatNumber']}
                                    fieldKey={[fieldKey, 'seatNumber']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="Seat #" disabled={true} className="seatNumberInput" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={isShowLabel?"Passenger name":null}
                                    {...restField}
                                    name={[name, 'fullName']}
                                    fieldKey={[fieldKey, 'fullName']}
                                    rules={[{ 
                                        required: boardingDropManditory,
                                        message: '',
                                        pattern: new RegExp(/^[a-zA-Z0-9\s\.]*$/)
                                    }]}
                                    tooltip="Only English alphabets, numbers and space are allowed"
                                >
                                    <Input 
                                    placeholder="Passenger Name" 
                                    className="passengerNameInput" 
                                    onChange={(e)=>{
                                        onFormChange({fullName:e.target.value,seatId:seats[key].id})
                                    }}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <span>Seat no:</span> */}
                            <Col>
                                <Form.Item
                                    {...restField}
                                    label={isShowLabel?"Gender":null}
                                    name={[name, 'gender']}
                                    fieldKey={[fieldKey, 'gender']}
                                    rules={[{ required: true, message: '' }]}
                                    >
                                    <Radio.Group
                                    onChange={(e)=>{
                                        onFormChange({gender:e.target.value,seatId:seats[key].id})
                                    }}>
                                        <Radio value="M">M</Radio>
                                        <Radio value="F">F</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col>
                                    <Form.Item
                                        {...restField}
                                        label={isShowLabel?"Age":null}
                                        name={[name, 'age']}
                                        fieldKey={[fieldKey, 'age']}
                                        rules={[{
                                            required: boardingDropManditory, 
                                            pattern: new RegExp(/^[0-9]*$/),
                                            message: '' }]}
                                        tooltip="Only numbers are allowed"
                                        >
                                        <Input placeholder={"Age"}  className="ageInput"
                                          onChange={(e)=>{
                                            onFormChange({age:e.target.value,seatId:seats[key].id})
                                        }}
                                        />
                                    </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={isShowLabel?"Fare":null}
                                    {...restField}
                                    name={[name, 'discountedCostWithoutGst']}
                                    fieldKey={[fieldKey, 'discountedCostWithoutGst']}
                                    rules={[{ required: false, message: 'Please enter seat number' }]}
                                >
                                    <Input placeholder="fare" disabled={true} className="fareInput" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    )})}
                </>
                )}
            </Form.List>
            <Row gutter={16} className="phone-number-block">
                <Col>
                    <Form.Item 
                     label="Phone no" 
                     rules={[{ required: boardingDropManditory, message: 'Please enter customer phone no' }]}
                     tooltip="Please enter customer phone no"
                     name="phoneNumber"
                     >
                        <Input placeholder="Phone no" className="defalut-input-style" maxLength={10}/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        label="Email ID"
                        tooltip="Please enter customer email id"
                        name="emailId"
                    >
                        <Input placeholder="Email ID" className="defalut-input-style"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                <Form.Item
                    label="Boarding Point"
                    name="boardingPointId"
                    tooltip="Please select boarding point"
                    rules={[{ required: boardingDropManditory, message: 'Please select boarding point' }]}
                >
                        <Select
                                size="large"
                                showSearch
                                placeholder="Select a Boarding Point"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                        option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                bordered={true}
                                className="defalut-select-style"
                                popupClassName="defalut-select-drop-down"
                                >
                                {renderBoardingsPoints(stopPoint.pickupDetails,selectedDate)}
                        </Select>
                </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        label="Drop Point"
                        tooltip="Please select drop point"
                        rules={[{ required: boardingDropManditory, message: 'Please select drop point' }]}
                        name="dropPointId"
                    >
                        <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select a Drop Point"
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    bordered={true}
                                    className="defalut-select-style"
                                    >
                                    {renderDropPoints(stopPoint.dropDetails,selectedDate,selectedSchedule)}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {global.useRemarks === 1 &&
            <Row>
                <Col>
                    <Form.Item 
                        label="Remarks" 
                        rules={[
                            { required: false, message: 'Please enter remarks' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || (value.length < 25)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error(`Remarks cannot be greater than 25 characters.`));
                                },
                              })
                        ]}
                        tooltip="Please enter remarks"
                        name="remarks"
                     >
                        <Input placeholder="Remarks" className="defalut-input-style"/>
                    </Form.Item>
                </Col>
            </Row>
            }
            {isDiscountExits&&
             <Row align="middle">  
                <Col>
                    <Text 
                    // delete={isDiscountExits?true:false}
                    >Ticket Fare : {baseFare}</Text>
                </Col>
                {discountValue > 0 &&
                    <Col>
                        <AddDiscount 
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                        />
                    </Col>
                }
            </Row>
            }
            {discountValue>0&&
                <Row align='middle'>
                    <Col>
                        {`Discount : ${discountValue}`}
                    </Col>
                    <Col>
                        <EditDiscount 
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                        />
                    </Col>
                </Row>
            }
            <Row align='middle'>
                    <Col>
                        {isDiscountExits?"Discounted ":''}Ticket Fare : {discountedCostWithoutGst}
                    </Col>
                    {!isDiscountExits&&
                        <Col>
                            <AddDiscount 
                                discountValue={discountValue}
                                setDiscountValue={setDiscountValue}
                            />
                        </Col>
                    }
            </Row>
            <Row>
                CGST : {cgst}
            </Row>
            <Row>
                SGST : {sgst}
            </Row>
            <Row align='middle'>
                <Col>
                    Amount Paid : {totalCost} 
                </Col>
            </Row>
            {isValidArrayWithValues(agentsList)&&
                <Row>
                    <Col>
                        <Form.Item
                            label="Book as:"
                            rules={[{ required: false, message: 'Please select an agent' }]}
                            name="agentId"
                        >
                            <Select
                                    size="large"
                                    showSearch
                                    defaultValue= {global.agentId}
                                    placeholder="Select an Agent"
                                    optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    bordered={true}
                                    className="defalut-select-style"
                                    >
                                    {agentsList}
                            </Select>
                    </Form.Item>
                    </Col>
                </Row>
            }
            <Row gutter={[16,16]}>
                {checkActionAllowed(ACTION_BOOK_TICKET_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={bookButtonType}
                                buttonClassName={"book-button"}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,bookButtonType);
                                }}
                                children={<ConfirmBody buttonType={bookButtonType}/>}
                                onOk={bookAction}
                                okButtonName={bookButtonType}
                            />
                    </Col>
                }
                {checkActionAllowed(ACTION_BLOCK_TICKET_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={blockButtonType}
                                buttonClassName={'block-button'}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,blockButtonType);
                                }}
                                children={<ConfirmBody buttonType={blockButtonType}/>}
                                onOk={blockAction}
                                okButtonName={blockButtonType}
                            />
                    </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&
                    <Col>
                            <CustomModal 
                                buttonName={phoneBlock}
                                buttonClassName={'phone-block'}
                                onButtonClick={(e)=>{
                                    return handleSubmit(e,phoneBlock);
                                }}                                
                                children={<ConfirmBody buttonType={phoneBlock} isShowMins={true}/>}
                                onOk={blockAction}
                                okButtonName={phoneBlock}
                            />
                    </Col>
                }
                <Col>
                    <BlockByAdminComponent 
                            selectedSchedule={selectedSchedule}
                            onSuccess={onSuccess}
                            selectedSeats={seats}
                            disableAdminBlock={disableSetPrice}
                    />
                </Col>
                {isAnyBlockedSeatExits&&
                    <Col>
                         <ReleaseSeatsComponent 
                            selectedSchedule={selectedSchedule}
                            onSuccess={onSuccess}
                            selectedSeats={seats}
                         />
                    </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&isSendPhonePaymentLink&&
                    <Col>
                             <CustomModal 
                                 buttonName={sendPaymentLink}
                                 buttonClassName={'payment-link-btn'}
                                 onButtonClick={(e)=>{
                                     return handleSubmit(e,sendPaymentLink);
                                 }}                                
                                 children={<ConfirmBody buttonType={sendPaymentLink} isShowMins={true}/>}
                                 onOk={blockAction}
                                 okButtonName={sendPaymentLink}
                             />
                     </Col>
                }
                {checkActionAllowed(ACTION_PHONE_BLOCK_ALLOWED)&&isPhoneBlockSeat?
                    <Col>
                        <CopyPaymentLink />
                    </Col>
                    :null
                }
                <Col>
                    <SeatPriceComponent 
                        travelDate={selectedDate}
                        scheduleId={selectedSchedule.schedule_id}
                        bus={selectedSchedule.bus}
                        description={selectedSchedule.description}
                        selectedSeats={seats}
                        onSuccess={(msg)=>{
                            message.success(msg);
                            onFareUpdateSuccess(selectedSchedule.trip_route_id);
                        }}
                        disableSetPrice={disableSetPrice}
                    />
                </Col>
                {checkActionAllowed(ACTION_TO_VIEW_SET_GENDER_BTN)?
                <Col>
                    <ChangeGenderReservation 
                        disableSetPrice={disableSetPrice}
                        selectedSeats={seats}
                        tripId={selectedSchedule.trip_id}
                        onSuccess={onSuccess}
                    />
                </Col>
                :null
                }
            </Row>
        </Form>
         <div>
                {isPhoneBlockSeat&&
                    <h3>Release Time : {getLocalDateAndTime(timeOfRelease)}</h3>
                }
        </div>
    </div>
  );
};