import { 
Row,Col,Typography, Table, message, Button,Popover,Input,Select
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { fetchCancelledTickets, fetchTripSheet } from "../../../actions/reports.action";
import './trip-tickets.style.scss';
import {
    EllipsisOutlined
} from '@ant-design/icons';
import { SeatClickTicketPopUp } from "../../ticket-summary-components/seat-click-ticket-popup.component";
import { useHistory } from "react-router-dom";
import { selectedScheduleSeatsSelector, selectedScheduleSelector } from "../../../selector/home.selector";
import { isValid, isValidArray, checkUpdatedValueFromObjects, getCloneArray } from "../../../utils/utilities";
import { 
    useDispatch,
    useSelector
} from 'react-redux';
import { fetchSeatLayout, setPickupPointDetailsForSelectedSchedule, setSelectedScheduleSeats } from "../../../actions/home.action";
import { updateTransactionDetails } from "../../../actions/ticket.action";
import { loadingAction } from "../../../actions/loading-actions";
import { getLocalDateAndTime } from "../../../utils/date.utils";
import useColumnSearch from './../../../ui-components/table-column-search';
import ZoomComponent from './../../custom-components/zoom-component/zoom.component';
import { useContext } from "react";
import { DeviceContext } from "../../device-provider/device-provider";
import { getPickupLocationByTripId } from "../../ticket-summary-components/seat-click-ticket.module";
import moment from 'moment';
import { OTARefund } from "./ota_refund.component";
import { SendDriverDetails } from "../../bus-cancel-components/send-driver-details-modal/send-driver-details-modal.component";

const getConfirmedTickets=(confirmed)=>{
    let onlyConfirmedTickets = [],onlyBlockedTickets = [];
    for (let index = 0; index < confirmed.length; index++) {
        const element = confirmed[index];
        if(element.traveller_status == "Confirmed"){
            onlyConfirmedTickets.push(element);
        }else if(element.traveller_status == "Blocked"){
            onlyBlockedTickets.push(element);
        }
    }
    return {onlyConfirmedTickets,onlyBlockedTickets};
}   


const {
    Text
} = Typography;

const {
    Option,
    OptGroup
} = Select;

const renderBoardingPoints=(availableBoardings)=>{
    let availableBoardingsComponents=[];
    if(isValidArray(availableBoardings)){
        for (const key in availableBoardings) {
            if (Object.hasOwnProperty.call(availableBoardings, key)) {
                const boardingPoints = availableBoardings[key];
                let optionBoardingPoints=[];
                if(isValidArray(boardingPoints)&&boardingPoints.length>0){
                    boardingPoints.forEach(element => {
                        optionBoardingPoints.push(<Option value={element.id} data={element}>{element.location}</Option>);
                    });
                }
                availableBoardingsComponents.push(<OptGroup label={key}>{optionBoardingPoints}</OptGroup>);
            }
        }
    }
    return availableBoardingsComponents;
}

const renderDropPoints=(availableDrops)=>{

    let availableDropsComponents=[];
    if(isValidArray(availableDrops)&&availableDrops.length>0){
        availableDrops.forEach(element => {
            availableDropsComponents.push(<Option value={element.id}>{element.location}</Option>)
        });
    }
    return availableDropsComponents;
}

const renderAvailableSeats=(gender,bookedSeat,seats)=>{
    let seatAvailableOptions=[];
    seatAvailableOptions.push(<Option key={bookedSeat.id} value={bookedSeat.seat_number}>{bookedSeat.seat_number}</Option>);
    if(isValidArray(seats)&&seats.length>0){
        seats.forEach(seat => {
            if(seat.seat_number!=="Empty"&&(!isValid(seat.seat_status)||seat.seat_status === "")){
                if(gender==='F'){
                    if(seat.ladies_seat===1||seat.gents_seat===0){
                        seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                    }
                }else{
                    if(!(seat.ladies_seat===1)){
                        seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                    }
                }
            }
        });
    }
    return seatAvailableOptions;
}

const getFiltersOfConfirmTickets=(confirmTickets)=>{
    let  confirmBoardings =[],confirmDrops=[],confirmAgents=[];
    let  testconfirmBoardings =[],testconfirmDrops=[],testconfirmAgents=[];

    if(isValidArray(confirmTickets)&&confirmTickets.length>0){
        confirmTickets.forEach((ticket)=>{
            if(testconfirmBoardings.indexOf(ticket.location) == -1){
                testconfirmBoardings.push(ticket.location);
                confirmBoardings.push({
                    text:ticket.location,
                    value:ticket.location
                })   
            }

            if(testconfirmDrops.indexOf(ticket.drop_location) == -1){
                testconfirmDrops.push(ticket.drop_location);
                confirmDrops.push({
                    text:ticket.drop_location,
                    value:ticket.drop_location
                })   
            }

            if(testconfirmAgents.indexOf(ticket.agent_name) == -1){
                testconfirmAgents.push(ticket.agent_name);
                confirmAgents.push({
                    text:ticket.agent_name,
                    value:ticket.agent_name
                })   
            }
        });
    }


    return{
        confirmBoardings,confirmAgents,confirmDrops,confirmTicketsCount:confirmTickets.length
    }
}


const getFiltersOfCancelledTickets=(cancelledTickets)=>{
    let  cancelledBys =[],cancelledTravellerStatuses=[];
    let  testcancelledBys =[],testcancelledTravellerStatuses=[];

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach((ticket)=>{
            if(testcancelledBys.indexOf(ticket.cancelled_by) == -1){
                testcancelledBys.push(ticket.cancelled_by);
                cancelledBys.push({
                    text:ticket.cancelled_by,
                    value:ticket.cancelled_by
                })   
            }

            if(testcancelledTravellerStatuses.indexOf(ticket.traveller_status) == -1){
                testcancelledTravellerStatuses.push(ticket.traveller_status);
                cancelledTravellerStatuses.push({
                    text:ticket.traveller_status,
                    value:ticket.traveller_status
                })   
            }

        });
    }

    return{
        cancelledBys,cancelledTravellerStatuses,cancelTicketsCount:cancelledTickets.length
    }
}

export const TripTicketsComponent = (props) => {

    let {
        tripId
    } = props;

    const [confirmTickets,setConfirmTickets] = useState([]);
    const [cachedConfirmTickets,setCachedConfirmTickets] = useState([]);
    const [filters,setFilters] = useState({});
    const [blockedTickets,setBlockedTickets] = useState([]);
    const [vechicleInfo,setVechicleInfo] = useState({
        scheduleVechicleId:null,
        tripVechicleId:null
    });


    const [cancelledTickets,setCancelledTickets] = useState([]);
    const [notBoardedSeats , setNotBoardedSeats]=useState([]);

    const [editId,setEditId] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const {
        isMobile
    }=useContext(DeviceContext);

    const moveToViewCancel=(pnr)=>{
        history.push('/view-cancel?pnr='+pnr);
    }

    const updateConfirmTicketsState=(index,value,label,pickupLocation)=>{
        confirmTickets[index][label] = value;
        if(label==="pickup_location_id"){
            confirmTickets[index]['location'] = pickupLocation.location;
        }
        setConfirmTickets([...confirmTickets]);
    }

    const selectedSchedule=useSelector(selectedScheduleSelector);
    const seats = useSelector(selectedScheduleSeatsSelector);

    useEffect(()=>{
        let{
            confirmBoardings,
            confirmDrops,
            confirmAgents,
            confirmTicketsCount
        } = getFiltersOfConfirmTickets(confirmTickets);
        filters.confirmBoardings = confirmBoardings;
        filters.confirmDrops = confirmDrops;
        filters.confirmAgents = confirmAgents;
        filters.confirmTicketsCount = confirmTicketsCount;
        setFilters(filters);
    },[confirmTickets]);
    

    useEffect(()=>{
        let{
            cancelledBys,
            cancelledTravellerStatuses,
            cancelTicketsCount
        } = getFiltersOfCancelledTickets(cancelledTickets);
        filters.cancelledBys = cancelledBys;
        filters.cancelledTravellerStatuses = cancelledTravellerStatuses;
        filters.cancelTicketsCount = cancelTicketsCount;
        setFilters(filters);
    },[cancelledTickets]);

    useEffect(()=>{
        let{
            cancelledBys,
            cancelledTravellerStatuses,
            cancelTicketsCount
        } = getFiltersOfCancelledTickets(notBoardedSeats);
        filters.notBoardedCancelledBys = cancelledBys;
        filters.notBoardedCancelledTravellerStatuses = cancelledTravellerStatuses;
        filters.notBoardedSeatsTicketsCount = cancelTicketsCount;
        setFilters(filters);
    },[notBoardedSeats]);

    useEffect(()=>{
            feacthSeats();
    },[]);

    const feacthSeats=()=>{
        if(isValidArray(selectedSchedule)){
            dispatch(fetchSeatLayout(selectedSchedule.trip_route_id,(seatsData)=>{
                let fetchedSeats = seatsData.seats;
                setSeats(fetchedSeats);
            },(errmsg)=>{
                message.error(errmsg);
                setLoading(false);
            }));
        }
    }

    const setSeats=(data)=>{
        dispatch(setSelectedScheduleSeats(data));
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onUpdateSuccess=()=>{
        setEditId(null);
        setLoading(false);
    }

    const onUpdateFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    let pickupTimings= {} , allPickUpTimings={};

    if(isValidArray(selectedSchedule)){
        // Note : We need show ticket origin and search origin
        pickupTimings = selectedSchedule.pickupTimings;
        allPickUpTimings = selectedSchedule.pickupTimingsByTripCity;
    }

    const setPickupTimingsByTripCity =(info)=>{
        dispatch(setPickupPointDetailsForSelectedSchedule(info));
      }

      useEffect(()=>{
        if (isValidArray(selectedSchedule)&&!isValid(selectedSchedule.pickupTimingsByTripCity)) {
            getPickupLocationByTripId(selectedSchedule.schedule_id,selectedSchedule.start_date,selectedSchedule.trip_id, setPickupTimingsByTripCity)
        }
      },[selectedSchedule])


    const confirmTicketsColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number',
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return (
                        <Select value={row.seat_number} className="seat-number-select"
                        onChange={(value)=>{
                            updateConfirmTicketsState(index,value,'seat_number');
                        }}
                        >
                        {renderAvailableSeats(row.gender , cachedConfirmTickets[index] , seats)}
                        </Select>)                
                    }
                    else{
                        return text;
                }
            }
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            width:150,
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return(
                        <Input value = {text} style={{width:'130px'}}  onChange={(e)=>{
                            updateConfirmTicketsState(index,e.target.value,'full_name');
                        }}/>
                    )
                }else{
                    return(
                        <Popover 
                        // visible={showMenu}
                        trigger={["focus"]}
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                    <SeatClickTicketPopUp 
                                        ticketId = {row.ticket_id}
                                        seatNumber = {row.seat_number}
                                        gender = {row.gender} 
                                        agentId = {row.agent_id}
                                        children={<Button className="outline-button">View</Button>}
                                        isOnClickExits={true} 
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        setEditId(row.travellerId);
                                    }}>Edit</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        moveToViewCancel(row.pnr);
                                    }}>Cancel</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} justify="center"  align="middle">
                                <Col>
                                    <Button 
                                    className="outline-button"  
                                    onClick={()=>{
                                        moveToViewCancel(row.pnr);
                                    }}>Not boarded</Button>
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle">
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                            <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
              }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    return <Input value = {text} style={{width:'130px'}}  onChange={(e)=>{
                        updateConfirmTicketsState(index,e.target.value,'phone_number');
                    }}/>
                }else{
                    return text;
                }
            }
        },
        {
            title:'Boarding',
            dataIndex:'location',
            key:'location',
            filters:filters.confirmBoardings,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
            render:(text,row)=>{
                const index = confirmTickets.findIndex(item => row.travellerId === item.travellerId);
                if(row.travellerId === editId){
                    let newPickupTimings={};
                    //  Note : We need show ticket origin and search origin
                    if(isValidArray(selectedSchedule)&&isValid(selectedSchedule.origin)){
                        newPickupTimings[selectedSchedule.origin] = pickupTimings;
                    }
                    if(isValidArray(allPickUpTimings)&&isValid(allPickUpTimings[row.pick_up_city])){
                        newPickupTimings[row.pick_up_city] = allPickUpTimings[row.pick_up_city];
                    }
                    return (
                        <Select className="boarding-point-select" value={row.pickup_location_id}
                        onChange={(value,optionData)=>{
                            let{
                                data
                            } = optionData;
                            updateConfirmTicketsState(index,value,"pickup_location_id",data);
                       }}
                       >
                           {renderBoardingPoints(newPickupTimings)}
                       </Select>
                    )
                }else{
                    return text;
                }
            }
        },
        {
            title:'Drop',
            dataIndex:'drop_location',
            key:'drop_location',
            filters:filters.confirmDrops,
            onFilter: (value, record) => record.drop_location.indexOf(value) === 0,
            render:(text)=>{
                    return text;
                // Not supported drop point change
                // if(row.travellerId === editId){
                //     return (
                //         <Select className="seat-gender-select" value={row.drop_location_id}
                //         onChange={(value)=>{
                //             updateConfirmTicketsState(index,value,"drop_location_id")
                //        }}
                //        >
                //            {renderDropPoints(dropTimings)}
                //        </Select>
                //     )
                // }else{
                //     return text;
                // }
            }
        },
        {
            title:'Agent',
            dataIndex:'agent_name',
            key:'agent_name',
            filters:filters.confirmAgents,
            onFilter: (value, record) => record.agent_name.indexOf(value) === 0
        },
        {
            title:'Booking time',
            dataIndex:'booking_time',
            render:(text,row)=>{
                return getLocalDateAndTime(text);
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.ticket_id - b.ticket_id,
            sortDirections :['ascend', 'descend']
        }
    ];

    const cancelledTicketsColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number'
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                    return(
                        <Popover 
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                    <SeatClickTicketPopUp 
                                        ticketId = {row.ticket_id}
                                        seatNumber = {row.seat_number}
                                        gender = {row.gender} 
                                        agentId = {row.agent_id}
                                        children={<Button className="outline-button" >View</Button>}
                                        isOnClickExits={true} 
                                    />
                                </Col>
                                <Col>
                                    <OTARefund 
                                       travellerStatus={row.traveller_status}
                                       busCancellation={row.bus_cancellation}
                                       agentPrvilleage={row.agent_privilege}
                                       automaticRefund={row.automatic_refund}
                                       traveller={row}
                                       reload={reload}
                                    />
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle" >
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                                <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
        },
        {
            title:'Cancel Time',
            dataIndex:'cancellation_time',
            key:'cancellation_time',
            render : (text) => {
                if(isValid(text)){
                    return getLocalDateAndTime(text)
                }else{
                    return ''
                }
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.cancellation_time).unix() - moment(b.cancellation_time).unix(),
            sortDirections :['ascend', 'descend']
        },
        {
            title:'Cancelled By',
            dataIndex:'cancelled_by',
            key:'cancelled_by',
            filters:filters.cancelledBys,
            onFilter: (value, record) => record.cancelled_by.indexOf(value) === 0,
        },
        {
            title:'Status',
            dataIndex:'traveller_status',
            key:'traveller_status',
            filters:filters.cancelledTravellerStatuses,
            onFilter: (value, record) => record.traveller_status.indexOf(value) === 0,
        },
        {
            title:'Refund',
            dataIndex: 'automatic_refund',
            key: 'automatic_refund',
            render: (text) => {
                if(text) {
                    return "Success"
                }
                else {
                    return null
                }
            }
        }
    ];

    const notBoardedColumns=[
        {
            title:'Seat#',
            dataIndex:'seat_number',
            key:'seat_number'
        },
        {
            title:'Name',
            dataIndex:'full_name',
            key:'full_name',
            ...useColumnSearch(
                {dataIndexExt:"full_name"}
            ),
            render:(text,row)=>{
                    return(
                        <Popover 
                        content={
                        <div>
                            <Row gutter={[16,16]} justify="center"  align="middle" style={{width:'150px'}}>
                                <Col>
                                    <SeatClickTicketPopUp 
                                        ticketId = {row.ticket_id}
                                        seatNumber = {row.seat_number}
                                        gender = {row.gender} 
                                        agentId = {row.agent_id}
                                        children={<Button className="outline-button" >View</Button>}
                                        isOnClickExits={true} 
                                    />
                                </Col>
                                <Col>
                                
                                </Col>
                            </Row>
                        </div>}
                    >
                        <Row justify="center" align="middle" >
                            <Col span={20}>{text}</Col>
                            <Col span={4}>
                                <Button  
                                    type="text" 
                                    className="outline-button three-dots-button"  
                                    icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                                />
                            </Col>
                        </Row>
                    </Popover>
                    )
                }
        },
        {
            title:'Phone',
            dataIndex:'phone_number',
            key:'phone_number',
            ...useColumnSearch(
                {dataIndexExt:"phone_number"}
            ),
        },
        {
            title:'Cancel Time',
            dataIndex:'cancellation_time',
            key:'cancellation_time',
            render : (text) => {
                if(isValid(text)){
                    return getLocalDateAndTime(text)
                }else{
                    return ''
                }
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.cancellation_time).unix() - moment(b.cancellation_time).unix(),
            sortDirections :['ascend', 'descend']
        },
        {
            title:'Cancelled By',
            dataIndex:'cancelled_by',
            key:'cancelled_by',
            filters:filters.notBoardedCancelledBys,
            onFilter: (value, record) => record.cancelled_by.indexOf(value) === 0,
        },
        {
            title:'Status',
            dataIndex:'traveller_status',
            key:'traveller_status',
            filters:filters.notBoardedCancelledTravellerStatuses,
            onFilter: (value, record) => record.traveller_status.indexOf(value) === 0,
        }
    ];

    if(isValid(editId)){
        confirmTicketsColumns.push({
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            width:250,
            render:(text,row)=>{
                if(editId === row.travellerId){
                    return(
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button 
                                className="cancel-button"
                                onClick={()=>{
                                    setEditId(null);
                                    setConfirmTickets(cachedConfirmTickets);
                                }}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button 
                                    onClick={()=>{
                                        let ticketObject = {
                                            ticket:{
                                                id:row.ticket_id,
                                                phone_number:row.phone_number,
                                                trip_id:row.trip_id,
                                                email_id:row.email_id,
                                                remarks:row.remarks
                                            },
                                            traveller:[
                                                {
                                                    seat_number:row.seat_number,
                                                    traveller_status:row.traveller_status,
                                                    id:row.travellerId,
                                                    full_name:row.full_name,
                                                    pickup_location:{
                                                        id:row.pickup_location_id
                                                    },
                                                    gender:row.gender
                                                }
                                            ],
                                            trip:{
                                                id:row.trip_id
                                            }
                                        }
                                        let rowObj, tktObj;
                                        let keyList = ['ticket_id','phone_number','email_id','remarks','seat_number','traveller_status','travellerId','full_name','pickup_location_id','gender'];
                                        cachedConfirmTickets.map((tkts) => {
                                            if (row.travellerId == tkts.travellerId) {
                                                rowObj = copyJsonKeyVal(keyList, {...row});
                                                tktObj = copyJsonKeyVal(keyList, {...tkts});
                                            }
                                        })
                                        if(checkUpdatedValueFromObjects(rowObj,tktObj)) {
                                            setLoading(true);
                                            updateTransactionDetails(ticketObject,onUpdateSuccess,onUpdateFailure);
                                        }
                                        else {
                                            message.warn("No Value to Update");
                                            onUpdateSuccess();
                                        }
                                    }}
                                >Save</Button>
                            </Col>
                        </Row>)
                }else{
                    return null
                }
            }
        })
    }

    const copyJsonKeyVal = (keyList,obj) => {
        let returnObject = {};
        keyList.map((key) => {
            returnObject[key] = obj[key];
        })
        return returnObject
    }

    const onSuccess = (data) => {
        let {onlyBlockedTickets,onlyConfirmedTickets} = getConfirmedTickets(data.tripSheet);
        setConfirmTickets(onlyConfirmedTickets);
        setBlockedTickets(onlyBlockedTickets);
        let copiedObject = getCloneArray(onlyConfirmedTickets);
        setCachedConfirmTickets(copiedObject);
        setNotBoardedSeats(data.notBoardedSeats);
        if(isValidArray(data.vechicleInfo)){
            setVechicleInfo({
                scheduleVechicleId:data.vechicleInfo.schedule_vechicle_id,
                tripVechicleId:data.vechicleInfo.trip_vechicle_id
            });
        }
    }

    const onFailure = (msg) => {
        message.error(msg);
    }

    const fetchReport = async(id)=>{
        return await fetchTripSheet(id,onSuccess,onFailure);
    }

    const onCancelledSuccess=(data)=>{
        setCancelledTickets(data);
    }

    const onCancelledFailure=(msg)=>{
        message.error(msg);
    }

    const reload=()=>{
        fetchCancelledTickets(tripId,onCancelledSuccess,onCancelledFailure)
        fetchReport(tripId);
    }

    useEffect(()=>{
        reload();
    },[tripId]);
    
    var blockedTicketColumns=confirmTicketsColumns.concat({
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render:(text,row)=>{       
            return (
                <SendDriverDetails 
                    ticketId={row.ticket_id} 
                    scheduleVechicleId={vechicleInfo.scheduleVechicleId}
                    tripVechicleId={vechicleInfo.tripVechicleId}
                />
            )
        }
    });
        return (
            <ZoomComponent isZoomEnabled={isMobile}>
                    <div className="trip-tickets-root tab-block" >
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Confirmed Seats ({confirmTickets.length}) Showing({filters.confirmTicketsCount})</Text>
                                    </Col>
                                </Row>
                                {confirmTickets.length>0&&
                                        <Row gutter={[16,16]}>
                                            <Col>
                                                <Table 
                                                    columns={confirmTicketsColumns}
                                                    dataSource={confirmTickets}
                                                    className="passenger-bordered-table"
                                                    bordered
                                                    pagination={false}
                                                    onChange={(pagination,tableFilters,sorter,extra)=>{
                                                        filters.confirmTicketsCount = extra.currentDataSource.length;
                                                        setFilters({...filters});
                                                    }}
                                                    scroll={{
                                                        x: 400
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                }
                                {blockedTickets.length>0&&
                                    <div>
                                        <Row gutter={[16,16]}>
                                            <Col>
                                                <Text strong>Blocked Seats ({blockedTickets.length}) Showing({filters.blockedTicketsCount})</Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16,16]}>
                                            <Col>
                                                <Table 
                                                    columns={blockedTicketColumns}
                                                    dataSource={blockedTickets}
                                                    className="passenger-bordered-table"
                                                    bordered
                                                    pagination={false}
                                                    onChange={(pagination,tableFilters,sorter,extra)=>{
                                                        filters.blockedTicketsCount = extra.currentDataSource.length;
                                                        setFilters({...filters});
                                                    }}
                                                    scroll={{
                                                        x: 400
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Cancelled ({cancelledTickets.length}) Showing({filters.cancelTicketsCount})</Text>
                                    </Col>
                                </Row>
                                {cancelledTickets.length>0&&
                                    <Row gutter={[16,16]}>
                                    <Col>
                                        <Table 
                                            bordered
                                            columns={cancelledTicketsColumns}
                                            dataSource={cancelledTickets}
                                            className="passenger-bordered-table"
                                            pagination={false}
                                            onChange={(pagination,tableFilters,sorter,extra)=>{
                                                filters.cancelTicketsCount = extra.currentDataSource.length;
                                                setFilters({...filters});
                                            }}
                                            scroll={{
                                                x: 400,
                                              }}
                                        />
                                    </Col>
                                    </Row>
                                }
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong> Not Boarded Seats ({notBoardedSeats.length}) Showing ({filters.notBoardedSeatsTicketsCount})</Text>
                                    </Col>
                                </Row>
                                {notBoardedSeats.length>0&&
                                    <Row gutter={[16,16]}>
                                        <Col>
                                            <Table 
                                                bordered
                                                columns={notBoardedColumns}
                                                dataSource={notBoardedSeats}
                                                className="passenger-bordered-table"
                                                pagination={false}
                                                onChange={(pagination,tableFilters,sorter,extra)=>{
                                                    filters.notBoardedSeatsTicketsCount = extra.currentDataSource.length;
                                                    setFilters({...filters});
                                                }}
                                                scroll={{
                                                    x: 400,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </div>
            </ZoomComponent>
        );
}