
import { isValidString , getArrayfromString, isValid, isValidIndex, isValidArray} from "../../utils/utilities";
import { postCall } from "./../../utils/network.util";
import { UPDATE_FARE_FOR_TRIP_ROUTES_URL, UPDATE_TRIP_DYNAMIC_FARE_URL } from './../../utils/api-url';
import { message } from "antd";


  export const getRoutesWithFareJson = (data,seatGroupIds)=>{
    let formatedData = [] , origins=[] , destinations=[];
    data.forEach(element => {
      let fareJson = {};
      let newFareJson={};
      let fare = element.fare;
      if(isValidString(element.fare_json)){
        fareJson = JSON.parse(element.fare_json);
      }
      if(isValidString(seatGroupIds)){
        let seatsArray=getArrayfromString(seatGroupIds);
        seatsArray.forEach((fareJsonKey, index) => {

          if(!isValid(fareJson[fareJsonKey])){
            // newFareJson[fareJsonKey] = fare; 
          }else{
            newFareJson[fareJsonKey] = fareJson[fareJsonKey]; 
          }
        })
      }
      element.fare_json = JSON.stringify(newFareJson);
      formatedData.push(element);
      if(origins.indexOf(element.origin) === -1){
        origins.push(element.origin);
      }
      if(destinations.indexOf(element.destination) === -1){
        destinations.push(element.destination);
      }
    });
    return {
      formatedData,
      filteredOrigins:origins,
      filteredDestinations:destinations
    };
  }

  
  export const updateScheduleTripFare=(
    routes,
    travelDates,
    scheduleId,
    bus,
    isUpdateSeatGroupIds,
    seats=null,
    onSuccess=()=>{
      return true;
    },
    onFailure=()=>{
      return false;
    }
    )=>{
      if(isValidArray(travelDates)&&travelDates.length>0){
        let tripRoutes=[];

        for(var i=0;i<routes.length;i++){
          if(routes[i].is_fare_edited){
            tripRoutes.push({
              route_id : routes[i].id,
              fare : routes[i].fare,
              cgst : (bus.cgst_percentage*routes[i].fare)/100,
              sgst :(bus.sgst_percentage*routes[i].fare)/100,
              igst :(bus.igst_percentage*routes[i].fare)/100,
              sleeper_fare : routes[i].sleeper_fare,
              trip_route_id : routes[i].trip_route_id,
              fare_json : routes[i].fare_json,
              start_time: routes[i].start_time,
              end_time: routes[i].end_time
            });
          }
        }
        return postCall(
              UPDATE_FARE_FOR_TRIP_ROUTES_URL,{
              "scheduleId" : scheduleId,
              "tripRoutes" : tripRoutes,
              "travelDates" : travelDates ,
              "isUpdateSeatGroupIds":isUpdateSeatGroupIds,
              "seats":seats,
              "userName":global.userName
            }).then(findresponce => {
            if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
                return onSuccess("Update Fare Successfully");
            }else{
                return onFailure(findresponce.payload.Message);
            }
         });
      }else{
        onFailure("Please select date to update fare!");
      }
   }

   export const getVisibleRoutes =(filteredRoutes,routes)=>{
     let formatedData=[];
      for (let index = 0; index < routes.length; index++) {
        const element = routes[index];
        const rowIndex = filteredRoutes.findIndex(item =>  element.id === item.id);
        if(isValidIndex(rowIndex)&&rowIndex!==-1){
          formatedData.push(element);
        }
      }
      return formatedData;
   }

   export const updateDynamicFareForTrip=(
    travelDates,
    scheduleId,
    onSuccess=()=>{
      return true;
    },
    onFailure=()=>{
      return false;
    }
    )=>{
      if(isValidArray(travelDates)&&travelDates.length>0){
        return postCall(
            UPDATE_TRIP_DYNAMIC_FARE_URL,{
              "scheduleId" : scheduleId,
              "travelDates" : travelDates 
            }).then(findresponce => {
            if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
                message.success("Update Fare Successfully");
                return onSuccess();
            }else{
                return onFailure(findresponce.payload.Message);
            }
         });
      }else{
        onFailure("Please select date to update fare!");
      }
   }