import { message } from 'antd';
import { PUSH_USAGE_URL } from '../utils/api-url';
import { isValidArray } from '../utils/utilities';
import { roundNumber } from '../utils/math.utils';
import { postCall } from '../utils/network.util';

export const pushUsage = (fromDate,toDate,workFlowSummary,onSuccess=()=>{},onFailure=()=>{}) => {
    let skuData=[];    
    workFlowSummary.forEach(element => {
        if(element.operatorCode != "SRMS" && element.operatorCode != "DEMO"){
            skuData.push({
                operatorCode:element.operatorCode,
                items:[
                    {
                        sku:"SMS",
                        quantity:element.smsCount
                    },
                    {
                        sku:"STDTRP",
                        quantity:element.serviceCounts
                    },
                    {
                        sku:"SSTRP",
                        quantity:element.seatSharingServiceCount
                    },
                    {
                        sku:"WEBCH",
                        quantity:roundNumber(element.confirmedAmount + element.cancelledAmount)
                    },
                    {
                        sku:"QBUS",
                        quantity:roundNumber(element.mavenConfirmedFare)
                    },
                    {
                        sku:"API",
                        quantity:roundNumber(element.mavenConfirmedFare)
                    }
                ]
            });
        }

    });
    var created = postCall(PUSH_USAGE_URL, {  
        fromDate,
        toDate ,
        "skuData":skuData    
    });
          created.then((data) => {            
          if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
              onSuccess(data.payload.Message);
          }else{
            onFailure(data.payload.Message);
          }
        })
        .catch((reason)=> {
            console.log({reason});
            onFailure(`Push data failed`);
        })
  }