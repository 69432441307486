import { CLEAR_TRAVELLER_INFO_FROM_SEAT_URL } from '../../utils/api-url';
import { postCall } from "../../utils/network.util";


 export const handleClearSeats=(tripId,seatNumber,ticketId,cutRouteEnabled,onSuccess=()=>{},onFailure=()=>{})=>{
      var cleared = postCall(CLEAR_TRAVELLER_INFO_FROM_SEAT_URL, {
        tripId,seatNumber,ticketId,cutRouteEnabled,
        updatedBy: global.userName 
      });
      cleared.then((data) => {
        if(data){
          onSuccess();
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }